<!-- @format -->

<template>
  <div class="flex flex-col p-8 bg-white rounded-lg gap-6">
    <div class="text-2xl font-normal text-gray-700">Create Compliance</div>
    <div>
      <div class="text-sm">Select Compliance Type</div>
      <v-select
        class="pt-2"
        label="name"
        v-model="complianceType"
        :options="complianceTypes"
        :clearable="false"
        placeholder="Select Compliance Type"
      />

      <small v-if="errors.complianceType" class="text-red">{{
        errors.complianceType
      }}</small>
      <div
        v-if="complianceTypeFlag && complianceType.name !== 'Policies'"
        class="flex items-center pt-2"
      >
        <input
          id="default-checkbox"
          type="checkbox"
          value=""
          v-model="isReOccuring"
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
        />
        <label
          for="default-checkbox"
          class="mx-1 text-sm font-medium text-gray-500"
          >Recurring</label
        >
      </div>
    </div>
    <div v-if="complianceTypeFlag" class="flex gap-6 w-full">
      <div class="w-full">
        <div class="text-sm pb-2">{{ complianceNameLabel }}</div>
        <input
          class="w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          type="text"
          v-model="complianceName"
          @change="errors.complianceName = ''"
          maxlength="100"
          :placeholder="complianceNameLabel"
        />
        <small v-if="errors.complianceName" class="text-red">{{
          errors.complianceName
        }}</small>
      </div>
      <div class="w-full" v-if="!complianceType?.name?.includes('Policies')">
        <div class="text-sm">Select Occurence</div>
        <input
          v-if="!isReOccuring"
          type="text"
          disabled
          :value="occurence === 1 ? 'once' : ''"
          class="w-full text-gray-700 border rounded py-3 px-4 mt-2 mb-3 leading-tight focus:outline-none focus:bg-white"
        />
        <v-select
          v-else
          class="pt-2"
          label="label"
          v-model="selectedOccurrence"
          @update:modelValue="createMinEndDate"
          :options="occurenceOptions"
          placeholder="Select Compliance Occurence"
        />
        <small v-if="errors.occurence" class="text-red">{{
          errors.occurence
        }}</small>
      </div>
    </div>
    <div v-if="complianceTypeFlag" class="flex gap-6 w-full">
      <div class="w-full">
        <div class="text-sm">Select Type</div>
        <v-select
          class="pt-2"
          label="compliance_type"
          v-model="complianceSubType"
          @update:modelValue="errors.complianceSubType = ''"
          :options="complianceSubTypes"
          :placeholder="`Select ${complianceType.name} type`"
        />

        <small v-if="errors.complianceSubType" class="text-red"
          >{{ errors.complianceSubType }}
        </small>
      </div>
      <div class="w-full" v-if="!isReOccuring">
        <div class="text-sm pb-2">Due Date</div>
        <div class="bg-white border border-gray-300 rounded">
          <DatePicker
            placeholder="Date to be achieved by"
            :needMinDate="true"
            :selectedDate="dueDate"
            @selected-date="setDueDate"
            @cleared-date="setDueDate"
          />
        </div>
        <small v-if="errors.dueDate" class="text-red">{{
          errors.dueDate
        }}</small>
      </div>
      <div class="w-full" v-if="isReOccuring && complianceTypeFlag">
        <div class="w-full">
          <div class="text-sm pb-2">End Date</div>
          <div class="bg-white border border-gray-300 rounded">
            <DatePicker
              placeholder="Date to be achieved by"
              :selectedDate="endDate"
              :needMinDate="true"
              :minDate="minEndDate"
              @selected-date="setEndDate"
              @cleared-date="setEndDate"
            />
          </div>
        </div>
        <small v-if="errors.endDate" class="text-red">{{
          errors.endDate
        }}</small>
      </div>
    </div>
    <div v-if="complianceTypeFlag" class="flex gap-6 w-full">
      <div class="w-full">
        <div class="text-sm">Choose Practices</div>
        <v-select
          class="pt-2"
          :multiple="true"
          label="practice_name"
          :closeOnSelect="false"
          v-model="selectedPractices"
          @update:modelValue="errors.practices = ''"
          :options="practices"
          placeholder="Select Practice"
        />
        <small v-if="errors.practices" class="text-red">{{
          errors.practices
        }}</small>
      </div>
    </div>

    <div
      class="flex items-center justify-center text-gray-700 border-dashed border-2 h-32 rounded-md"
      :class="errors.file ? 'border-red' : 'border-teal'"
      v-bind="getRootProps()"
    >
      <input v-bind="getInputProps()" @change="errors.file = ''" />
      <div v-if="!acceptedFiles">
        Drag or upload files here or
        <span
          class="cursor-pointer"
          :class="errors.file ? 'text-red' : 'text-teal'"
          >&nbsp;Browse here</span
        >
      </div>
      <div v-if="acceptedFiles">
        <p v-for="item in acceptedFiles" :key="item.name">
          {{ item.name }}
        </p>
      </div>
    </div>
    <small v-if="errors.file" class="text-red">{{ errors.file }}</small>
    <small v-if="!isFileUploaded.flag && !errors.file" class="text-red">{{
      isFileUploaded.msg
    }}</small>
    <div class="flex justify-between">
      <button
        class="px-8 py-3 bg-transparent border border-teal text-teal rounded-md font-light"
        :class="
          complianceType && !loadingButton
            ? 'opacity-100'
            : 'opacity-50 cursor-not-allowed'
        "
        @click.prevent="resetForm"
        :disabled="!complianceType"
      >
        Cancel
      </button>
      <button
        class="px-8 py-2 bg-teal border border-teal text-white rounded-md font-light"
        :class="
          complianceType && !loadingButton
            ? 'opacity-100'
            : 'opacity-50 cursor-not-allowed'
        "
        @click.prevent="submitForm"
        :disabled="complianceType && loadingButton"
      >
        Create
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import DatePicker from "../../../../components/newUi/atoms/DatePicker.vue";
import { useRouter } from "vue-router";
import { useDropzone } from "vue3-dropzone";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import icon from "@/assets/icons/success.svg";
const router = useRouter();
const store = useStore();

const complianceTypes = computed(() => {
  return store.getters["hqComplianceCalender/getComplianceTypes"];
});
const maxSize = 2000000;
const { getRootProps, getInputProps, rest } = useDropzone({
  onDrop,
  maxFiles: 1,
  maxSize: maxSize,
  accept: [".pdf", ".docx"],
});
const selectedOccurrence = ref(null);
const isFileUploaded = ref({ flag: true, msg: "" });
const practices = ref([]);
const occurence = ref(1);
const isReOccuring = ref(false);
const selectedPractices = ref([]);
const complianceSubTypes = ref(["Clinical", "Non-Clinical"]);
const complianceSubType = ref();
const loadingButton = ref(false);
const endDate = ref();
const dueDate = ref();
const restValue = ref(null);
const minEndDate = ref(new Date());
const acceptedFiles = ref(null);
const complianceName = ref("");
const occurenceOptions = ref([
  { id: 1, label: "One Month" },
  { id: 2, label: "Two Months" },
  { id: 3, label: "Three Months" },
  { id: 4, label: "Four Months" },
  { id: 5, label: "Five Months" },
  { id: 6, label: "Six Months" },
  { id: 7, label: "Seven Months" },
  { id: 8, label: "Eight Months" },
  { id: 9, label: "Nine Months" },
  { id: 10, label: "Ten Months" },
  { id: 11, label: "Eleven Months" },
  { id: 12, label: "Twelve Months" },
]);

const complianceType = ref(null);

const errors = ref({
  complianceType: "",
  complianceName: "",
  complianceSubType: "",
  occurence: "",
  dueDate: "",
  endDate: "",
  practices: "",
  file: "",
});

function onDrop(acceptFiles, rejectReasons) {
  acceptedFiles.value = acceptFiles;
  isFileUploaded.value.flag = true;
  isFileUploaded.value.msg = "";
  if (rejectReasons) {
    rejectReasons.map((item) => {
      acceptedFiles.value = null;
      if (item.errors[0].message.includes("Too many files")) {
        isFileUploaded.value.flag = false;
        isFileUploaded.value.msg = "Please select one file at a time!";
      }
      if (
        item.errors[0].message.includes("File is larger than 2000000 bytes")
      ) {
        isFileUploaded.value.flag = false;
        isFileUploaded.value.msg = "File Size Must Be Smaller Than 2MB";
      }
    });
  }
  restValue.value = rest;
}

const setDueDate = (date) => {
  errors.value.dueDate = "";
  dueDate.value = date ? date : null;
};

const setEndDate = (date) => {
  errors.value.endDate = "";
  endDate.value = date ? date : null;
};

const complianceTypeFlag = computed(() => {
  return complianceType?.value ? true : false;
});

const resetForm = () => {
  complianceType.value = null;
  complianceSubType.value = null;
  complianceName.value = null;
  isReOccuring.value = null;
  selectedOccurrence.value = null;
  dueDate.value = null;
  selectedPractices.value = [];
  acceptedFiles.value = null;

  errors.value.complianceName = "";
  errors.value.complianceSubType = "";
  errors.value.complianceType = "";
  errors.value.dueDate = "";
  errors.value.endDate = "";
  errors.value.file = "";
  errors.value.practices = "";
  errors.value.occurence = "";
};

const createMinEndDate = (month) => {
  errors.value.occurence = null;
  const date = new Date();
  minEndDate.value = add_months(date, month.id);
};

const add_months = (dt, n) => {
  return new Date(dt.setMonth(dt.getMonth() + n));
};

const complianceNameLabel = computed(() => {
  switch (complianceType?.value?.id) {
    case 1:
      return "Risk Assessment Name";
    case 2:
      return "Audit Name ";
    case 3:
      return "Policy Name";
    default:
      return "";
  }
});

const validateForm = () => {
  errors.value = {
    complianceType: "",
    complianceName: "",
    complianceSubType: "",
    occurence: "",
    dueDate: "",
    endDate: "",
    file: "",
    practices: "",
  };

  let valid = true;

  if (!complianceType.value) {
    errors.value.complianceType = "Compliance type is required.";
    valid = false;
  }
  if (!complianceSubType.value) {
    errors.value.complianceSubType = "Compliance Sub type is required.";
    valid = false;
  }
  if (!complianceName.value) {
    errors.value.complianceName = "Compliance name is required.";
    valid = false;
  }
  if (isReOccuring.value && !endDate.value) {
    errors.value.endDate = "End date is required for recurring compliance.";
    valid = false;
  }
  if (
    isReOccuring.value &&
    !selectedOccurrence.value &&
    complianceType.value.name !== "Policies"
  ) {
    errors.value.occurence = "Please select the valid occurence";
    valid = false;
  }
  if (!isReOccuring.value && !dueDate.value) {
    errors.value.dueDate = "Due date is required for non-recurring compliance.";
    valid = false;
  }
  if (selectedPractices.value.length === 0) {
    errors.value.practices = "At least one practice must be selected";
    valid = false;
  }

  if (!acceptedFiles.value) {
    errors.value.file = "Add File Please";
    valid = false;
  }
  return valid;
};

const submitForm = async () => {
  if (!validateForm()) return;

  const formData = new FormData();
  loadingButton.value = true;
  formData.append("type", JSON.stringify(complianceType.value.id));
  complianceType.value.name !== "Policies"
    ? formData.append("is_recurring", isReOccuring.value ? 1 : 0)
    : "";
  formData.append("name", complianceName.value);
  complianceType.value.name !== "Policies"
    ? formData.append(
        "occurrence",
        selectedOccurrence.value ? selectedOccurrence.value.id : occurence.value
      )
    : "";
  formData.append("sub_type", complianceSubType.value);
  !isReOccuring.value
    ? formData.append("due_date", dueDate.value)
    : formData.append("end_date", endDate.value);
  selectedPractices.value.forEach((practice, index) => {
    formData.append(`practices[${index}]`, practice.id);
  });
  if (acceptedFiles.value && acceptedFiles.value.length > 0) {
    acceptedFiles.value.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });
  }

  store
    .dispatch("hqComplianceCalender/createComplianceCalender", formData)
    .then(() => {
      Swal.fire({
        title: `${
          complianceType.value?.name.includes("Risk")
            ? complianceType.value?.name
            : complianceType.value?.name?.includes("Audits")
            ? "Audit"
            : "Policy"
        } Is Created Successfully`,
        iconHtml: `<img src="${icon}" />`,
        customClass: {
          title: "custom-title",
          popup: "border-radius",
          content: "custom-text",
          icon: "icon-border",
        },
        timer: 2000,
        showConfirmButton: false,
        background: `rgba(225, 249, 240, 1)`,
      });
      loadingButton.value = false;
      router.push({
        name: "hq-monitoringOfPracticeFunctionsComplianceDashboard",
      });
    })
    .catch(() => {
      loadingButton.value = false;
    });
};
onMounted(async () => {
  await store.dispatch("hqComplianceCalender/fetchComplianceTypes");
  practices.value = await store.dispatch("patientSurveys/loadPractices");
});
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 6px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
::v-deep input::placeholder {
  color: gray;
}
#default-checkbox {
  accent-color: rgba(0, 164, 153, 1);
  border: rgba(0, 164, 153, 1) solid 2px;
}
</style>
